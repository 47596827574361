import {
  CUSTOMER_LIST_ROUTE_NAME,
  ORDDER_FORM_ROUTE_NAME,
  PRODUCING_ROUTE_NAME,
  REVENUE_STATISTICS_ROUTE_NAME
} from './../../router/index';
import { Token } from '@/api/api';
import { USER_PERMISSION } from '../constants';
import jwt_decode from 'jwt-decode';

const TOKEN_NAME = 'accessToken';
export default class AuthStorageService {
  static getAuthInfo(): Token | null {
    const token = localStorage.getItem(TOKEN_NAME);
    return token ? JSON.parse(token) : null;
  }

  static setAuthInfo(token: Token) {
    localStorage.setItem(TOKEN_NAME, JSON.stringify(token));
  }

  static clearAuthInfo() {
    localStorage.removeItem(TOKEN_NAME);
  }

  static isFullPermission(): boolean {
    const token = this.getAuthInfo();
    return (
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ADMIN) ?? -1) !== -1
    );
  }

  static isProducingPermission(): boolean {
    const token = this.getAuthInfo();
    return (
      token?.claims?.length === 1 &&
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_PRODUCING) ?? -1) !==
        -1
    );
  }

  static isForbiddenChangePaymentStatus(): boolean {
    const token = this.getAuthInfo();
    return (
      token?.claims?.length === 1 &&
      (((token?.claims?.indexOf(USER_PERMISSION.PERMISSION_PRODUCING) ?? -1) !==
        -1 ||
        token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ORDER)) ??
        -1) !== -1
    );
  }

  static hasManagedPermissions(): boolean {
    const token = this.getAuthInfo();
    return (
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ADMIN) ?? -1) !== -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ORDER) ?? -1) !== -1
    );
  }

  static hasAccountantPermissions(): boolean {
    const token = this.getAuthInfo();
    return (
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ADMIN) ?? -1) !== -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ACCOUNTANT) ?? -1) !==
        -1
    );
  }

  static isNoPermission(): boolean {
    const token = this.getAuthInfo();
    return token?.claims?.length === 0;
  }

  static getAccessTokenInfo() {
    if (this.getAuthInfo()) {
      const data = jwt_decode(this.getAuthInfo()?.accessToken ?? '') as any;
      if (data) {
        return {
          userId: data.userid,
          displayName: data.displayName,
          sub: data.sub
        };
      }
    }
    return {};
  }

  static isIgnoreProcessSteps() {
    const token = this.getAuthInfo();
    return (
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ADMIN) ?? -1) !== -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ACCOUNTANT) ?? -1) !==
        -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_SALE) ?? -1) !== -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_ORDER) ?? -1) !== -1 ||
      (token?.claims?.indexOf(USER_PERMISSION.PERMISSION_SHIPPING) ?? -1) !== -1
    );
  }
}
