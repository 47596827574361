import { OrderDetailStatus } from '@/api/api';
import { DropdownOption } from './dropdowns/dropdownOptions';

export const MINIMUM_ORDER_DETAIL_TOTAL = 40000;
export const MAX_INT_ALLOW = 2147483647;
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const MACHINING_OPTIONS: DropdownOption[] = [
  {
    value: 0,
    text: 'Không cán màng'
  },
  {
    value: 1,
    text: 'Cán màng'
  }
];

export enum USER_PERMISSION {
  PERMISSION_SALE = 'PERMISSION_SALE',
  PERMISSION_ADMIN = 'PERMISSION_ADMIN',
  PERMISSION_ORDER = 'PERMISSION_ORDER',
  PERMISSION_PRODUCING = 'PERMISSION_PRODUCING',
  PERMISSION_ACCOUNTANT = 'PERMISSION_ACCOUNTANT',
  PERMISSION_DESIGN = 'PERMISSION_DESIGN',
  PERMISSION_SHIPPING = 'PERMISSION_SHIPPING',
  PERMISSION_PRINTING = 'PERMISSION_PRINTING'
}

export const COMPANY_INVOICE_INFO = 'COMPANY_INVOICE_INFO';

export const MODAL_IDS = {
  PAYMENT_NOTE_MODAL: 'payment-note-modal',
  EDIT_CUSTOMER_MODAL: 'edit-customer-modal',
  ORDER_DETAIL_MODAL: 'order-detail-modal',
  EXPENSES_DETAIL_MODAL: 'expenses-detail-modal',
  ORDER_ITEM_DETAIL_MODAL: 'order-item-detail-modal',
  ORDER_DEPOSIT_AMOUNT_MODAL: 'order-deposit-amount-modal',
  DEBT_SUMMARY_MODAL: 'debt-summary-modal'
};

export const PAYMENT_NOTE_TYPE = {
  COD: 'COD',
  BankTransfer: 'BankTransfer'
};

export const OrderDetailStatusChangeFlow = [
  {
    status: OrderDetailStatus.Delivered,
    previousValues: [
      OrderDetailStatus.Ordered,
      OrderDetailStatus.Printed,
      OrderDetailStatus.OrderReady
    ],
    nextValues: [OrderDetailStatus.Ordered]
  },
  {
    status: OrderDetailStatus.Printed,
    previousValues: [
      OrderDetailStatus.Ordered,
      OrderDetailStatus.Designing,
      OrderDetailStatus.ReDesign,
      OrderDetailStatus.WaitingForApprove
    ],
    nextValues: [OrderDetailStatus.OrderReady]
  },
  {
    status: OrderDetailStatus.OrderReady,
    previousValues: [
      OrderDetailStatus.Ordered,
      OrderDetailStatus.Printed,
      OrderDetailStatus.Designing,
      OrderDetailStatus.ReDesign,
      OrderDetailStatus.WaitingForApprove
    ],
    nextValues: [OrderDetailStatus.Delivered]
  },
  {
    status: OrderDetailStatus.Designing,
    previousValues: [OrderDetailStatus.Ordered],
    nextValues: [
      OrderDetailStatus.ReDesign,
      OrderDetailStatus.WaitingForApprove
    ]
  },
  {
    status: OrderDetailStatus.WaitingForApprove,
    previousValues: [OrderDetailStatus.Designing],
    nextValues: [OrderDetailStatus.ReDesign, OrderDetailStatus.Printed]
  },
  {
    status: OrderDetailStatus.ReDesign,
    previousValues: [OrderDetailStatus.Designing],
    nextValues: [OrderDetailStatus.WaitingForApprove]
  },
  {
    status: OrderDetailStatus.Ordered,
    previousValues: [],
    nextValues: [OrderDetailStatus.Printed, OrderDetailStatus.Designing]
  }
];

export const ACTIVITY_LOG_ENTITY = {
  ORDER: 'Order'
};
